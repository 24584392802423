<template>
  <v-container align-center justify-center fill-height pa-4>
    <v-layout class="background" text-center align-center justify-center column>
      <v-card :loading="loading" class="card">
        <img
          style="border-radius: 0px 0px 0px 0px !important; max-width: 180px"
          :src="logo"
        />
        <!-- <v-card-title style="justify-content: center" class="pa-5">
          <span class="display-1 font-weight-light px-5 text-center">
            {{ $t("register.title") }}
          </span>
        </v-card-title> -->
        <!-- <v-card-subtitle>{{ $tc("login.insert_message") }}</v-card-subtitle> -->
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @keyup.enter.native="onNext()"
          >
            <v-text-field
              v-model="name"
              :rules="[rules.required]"
              :label="$tc('register.name')"
              autocomplete="off"
              rounded
              filled
              clearable
            ></v-text-field>

            <v-text-field
              v-model="email"
              :rules="[rules.required, rules.email]"
              ref="mailAddress"
              type="email"
              name="input-10-1"
              :label="$t('register.email')"
              hint="correo@servidor.tldn"
              autocomplete="off"
              rounded
              filled
              clearable
            ></v-text-field>

            <v-text-field
              v-model="businessName"
              :rules="[rules.required]"
              type="email"
              name="input-10-1"
              :label="$t('register.company_name')"
              autocomplete="off"
              rounded
              filled
              clearable
            ></v-text-field>

            <v-text-field
              v-model="password"
              autocomplete="off"
              :append-icon="show1 ? 'fa-eye' : 'fa-eye-slash'"
              :rules="[rules.required, rules.min]"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              :label="$t('register.password')"
              :hint="$tc('login.min_password')"
              rounded
              filled
              clearable
              @click:append="show1 = !show1"
            ></v-text-field>

            <v-text-field
              v-model="confirmPassword"
              autocomplete="off"
              :rules="[rules.required, rules.min]"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              :label="$t('register.confirm_password')"
              :hint="$tc('login.min_password')"
              rounded
              filled
              clearable
            ></v-text-field>

            <v-btn
              color="buttons"
              rounded
              block
              large
              @click="onNext"
              :elevation="0"
              :minHeight="56"
              :loading="loading"
              :disabled="loading"
              >{{ $tc("register.btn") }}
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
      <v-snackbar
        content-class="centered"
        absolute
        top
        :timeout="3000"
        v-model="snackbar"
        >{{ snackbarText }}</v-snackbar
      >
    </v-layout>
  </v-container>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapState } from "vuex";

export default {
  name: "Register",

  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      snackbar: false,
      snackbarText: null,
      businessName : '',
      show1: false,
      loading: false,
      logo: require("@/assets/wink-logo.svg"),
      email: "",
      password: "",
      confirmPassword: "",
      valid: true,
      rules: {
        required: (value) => !!value || this.$t("rules.required"),
        min: (v) => (v && v.length >= 3) || this.$t("login.min_password"),
        email: (v) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || this.$t("login.invalid_email");
        },
      },
    };
  },
  methods: {
    onNext() {
      if (this.$refs.form.validate()) {
        if (this.password.length < 6 || this.password != this.confirmPassword) {
          this.snackbarText = this.$t("login.min_password");
          this.snackbar = true;
        } else {
            this.loading = true;
        }
      } else {
        this.snackbarText = this.$t("messages.insert_all_fields");
        this.snackbar = true;
      }
    },
  },
};
</script>

<style scoped>
.background {
  background-color: #008bbf;
  height: 100vh;
  position: absolute;
  width: 100vw;
  overflow: hidden;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: repeat;
}

.card {
  z-index: 100 !important;
  width: 450px;
}
</style>

